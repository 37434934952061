import { render, staticRenderFns } from "./LeadCreate.vue?vue&type=template&id=aa724e22&scoped=true&lang=pug"
import script from "./LeadCreate.vue?vue&type=script&lang=js"
export * from "./LeadCreate.vue?vue&type=script&lang=js"
import style0 from "./LeadCreate.vue?vue&type=style&index=0&id=aa724e22&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa724e22",
  null
  
)

export default component.exports