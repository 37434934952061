<template lang="pug">
  #ClientSearch
    form(@submit.prevent="toSearch" @keyup.enter="toSearch")
      img(:src="img.icoClient" width="22")
      label #[translate Buscar cliente]
      .input-group
        input(ref="search" id="product-search-query" class="form-control" type="search" v-model="q" :placeholder="placeholder.search" value="" @keyup="changeSearch" @search="toSearch()")
        span.input-group-btn
          button.btn.btn-action(type="submit")
            img(:src="img.lupa" width="16" title="Search")
      span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ClientSearch',
  data () {
    return {
      q: null,
      img: {
        icoClient: require('@/assets/img/icos/cliente.svg'),
        lupa: require('@/assets/img/icos/buscar.svg')
      },
      placeholder: {
        'search': this.$gettext('id, nombre, código postal, ciudad y provincia.')
      }
    }
  },
  methods: {
    changeSearch () {
      let ref = this
      clearTimeout(this.timerSearch)
      this.timerSearch = setTimeout(() => {
        // console.log('---busca')
        ref.toSearch()
      }, 1000)
    },
    toSearch () {
      let query = this.q

      if (query !== null && query !== '') {
        // console.log(`to search: ${query},  ${query.length}`)
        this.$emit('toSearch', [query])
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  label{
    font-weight: $regular;
    font-size:14px;
    color: #1C2C40;
    padding:0 30px 0 10px;

  }

  .input-group{
    max-width: 780px;
    display: inline-table;
    vertical-align: middle;
  }

  .btn-action{
    border-color: $white;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  #product-search-query{
    line-height: 40px;
    height: 40px;
  }


</style>
