<template lang="pug">
  #catalog-grid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .container(v-if="count == 0")
      .row
        .col-sm-12
          .msg-holder(:style="{backgroundImage: `url('${img.opssBg}')`}" role="alert")
            p
              img(:src="img.opss" alt="")
            div(v-if="category && $route.query.cat && ($route.name === 'catalogoSearch' || $route.name === 'leadSearch' || $route.name === 'catalogoPublicSearch')")
              p #[translate No hemos encontrado ningún producto con ese nombre dentro de ]
                strong {{category.name}}.
              p #[translate Si quieres puedes buscar en todas las categorías].
              p
                a(@click="goCatalogoList" class="btn btn-action" )
                  translate Buscar en todas las categorías

            div(v-else-if="complete === 'True' || (client && client.only_authorized_products === true)")
              p #[translate No se han encontrado productos].
            div(v-else)
              p #[translate No hemos encontrado ningún producto con ese nombre].
              p #[translate Si quieres puedes buscar en el catálogo completo]
              p
                button(class="btn btn-action" @click="changeFilterProductAll()") #[translate Buscar en todo el catálogo]
    .container
      .row
        .col-sm-12.product-list
          bk-item(v-for="product in aProduct" :key="product.id" :product="product" :view="view" :provinces="provinces" @toGoProduct="toGoProduct")
    .pagination-grid(v-if="totalPages > 1")
      ek-paginate(:page-count="totalPages"
        :page-range="4"
        :margin-pages="2"
        :click-handler="pagChange"
        :prev-text="paginationTxt.prev"
        :next-text="paginationTxt.next"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :initial-page="currentPage-1"
        v-show="totalPages"
        :key="componentKeyPaginate")
</template>
<script>
import BkItem from '@/components/catalog/Item-public.vue'
import EkPaginate from 'vuejs-paginate'

// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'
import SrvCotiza from '@/services/cotiza'

export default {
  name: 'CatalogGrid',
  components: { EkPaginate, BkItem },
  props: ['view', 'suppliers', 'category', 'query', 'ordering', 'typeProduct', 'filterByType'],
  data () {
    return {
      message: null,
      aProduct: [],
      count: null,
      totalPages: 0,
      currentPage: 1,
      componentKeyPaginate: 0, // para resetear el componente.
      complete: '', // para mostrar precios especiales si son autorizados o no --> a True muestra todo autorizados y no autorizados
      add_extra_products: '', // para mostrar los productos extra de orbis.
      only_usual_products: '', // para mostrar los artículos más consumidos.
      is_outlet: '', // para mostrar los artículos outlet.
      is_eco: '', // para mostrar los artículos eco.
      is_covid: '', // para mostrar los artículos covid.
      is_disinfection: '',
      is_innovative: '',
      orderby: '', // para ordenar los resultados, vacío se ordena Alfabéticamente.
      backNav: false, // cuando es true es que viene del delalle del producto a través del back del navegador.
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      setTimeoutToSearch: null,
      img: {
        'opss': require('@/assets/img/opss.png'),
        'opssBg': require('@/assets/img/opss_fondo.png')
      },
      provinces: {}
    }
  },
  computed: {
    center () {
      return this.$store.state.center.center
    },
    client () {
      return this.$store.getters.client
    },
    has_special_prices () {
      if (this.$store.getters.client) {
        return this.$store.getters.client.has_special_prices
      }
      return false
    },
    catalogueOptions () {
      return this.$store.state.client.catalogueOptions
    },
    idCat () {
      // console.log('------------', this.category)
      if (this.category) {
        return this.category.id
      }
      return ''
    }
  },
  created () {
    this.getProvinces()
  },
  mounted () {
    // console.log(`Mounted grid catálogo -> Complete: ${this.complete} , has_special_prices: ${this.has_special_prices}`)
  },
  methods: {
    init () {
      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        if (self.backNav) {
          self.backNav = false
          self.currentPage = self.$store.getters.cataloguePageCurrent // cogemos la pag de la store.
        } else {
          self.currentPage = 1 // Inicializamos la páginación
          self.componentKeyPaginate += 1 // reseteamos el componente de la paginación.
          window.scrollTo(0, 0)
        }
        self.toSearch(self.query, self.currentPage)
      }, 500)
    },
    pagChange (pageNum) {
      // console.log(pageNum)
      window.scrollTo(0, 0)
      this.toSearch(this.query, pageNum)
    },
    toSearch (query, page) {
      // para evitar hacer varias peticiones al mismo tiempo
      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.getProduct(query, page)
      }, 500)
    },
    toSearchAll () {
      // buscar en todo el catálogo.
      // console.log(`toSearchAll: ${this.query}`)
      this.toSearch(this.query, 1)
    },
    getProduct (query, page) {
      this.$store.dispatch('setCataloguePageCurrent', page)
      // console.log(`getProduct ----> complete: ${this.complete}, has_special_prices ${this.has_special_prices}, typeProduct: ${this.typeProduct}`)
      // Si no tiene productos autorizados/especiales le indicamos que saque todos los productos.
      // console.log(`--->toSearch: ${query}, page: ${page}, supplier: ${this.suppliers}, category: ${this.category}, complete: ${this.complete}, orden: ${this.orderby}, center: ${this.center}`)
      if (!this.typeProduct) {
        this.complete = (!this.has_special_prices) ? 'True' : this.complete
      }
      // window.scrollTo(0, 0)
      this.count = null
      SrvCatalog.getProductsPublic(query, page, this.idCat, this.complete, this.orderby, this.only_usual_products, this.is_outlet, this.is_eco, this.is_covid, this.is_disinfection, this.is_innovative, this.add_extra_products)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Producto: ', res.data.results)
            this.aProduct = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page

            if (this.count === 0) {
              // console.log(`complete: ${this.complete}, only_usual_products: ${this.only_usual_products}`)
            }
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeFilterProductAll () {
      this.catalogueOptions.filterViewProducts = 'all'
      // enviamos la actualización a la store.
      this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
    },
    toGoProduct (id) {
      this.$emit('toGoProduct', id)
    },
    goCatalogoList () {
      this.count = null // Para que se quite el mensaje de que no hay resultados.
      // repetimos la búsqueda sin la categoría.
      this.$router.push({'name': 'catalogoPublicSearch', 'query': { 'q': this.query }})
    },
    getProvinces () {
      SrvCotiza.getProvinces()
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`provinces: `, data)
            this.provinces = data.results
          }
        }, () => {
          // console.log('----->' + error)
        })
    }
  },
  watch: {
    '$route' (to, from) {
      // react to route changes...
      if (to.name === 'catalogoListPublic') {
        this.backNav = true
        // console.log(`watch router--> query: ${this.query}, page: ${this.currentPage}, backNav: ${this.backNav}`)
      }
    },
    supplier () {
      this.init()
    },
    query () {
      this.init()
    },
    category () {
      this.init()
    },
    center (newValue, oldValue) {
      // console.log(`watch center --> newValue: ${newValue}, oldValue: ${oldValue}`)
      if (!newValue || !oldValue || (newValue.id !== oldValue.id)) {
        this.init()
      }
    },
    client (newValue, oldValue) {
      // console.log(`watch client --> newValue: ${newValue}, oldValue: ${oldValue}`)
      if (!newValue || !oldValue || (newValue.id !== oldValue.id)) {
        // console.log('----cambio cliente:', this.client)
        this.init()
      }
    },
    ordering () {
      // console.log(`GRID cambio ordering: ${this.ordering}`)

      const orderBy = this.ordering.toString()

      switch (orderBy) {
        case 'price_asc':
          // Precio: bajo a alto
          this.orderby = 'final_price'
          break
        case 'price_desc':
          // Precio: alto a bajo
          this.orderby = '-final_price'
          break
        case 'consumption_asc':
          // Consumo: bajo a alto
          if (this.center) {
            this.orderby = 'total_consumption'
          }
          break
        case 'consumption_desc':
          // Consumo: alto a bajo
          if (this.center) {
            this.orderby = '-total_consumption'
          }
          break
        default:
          // Alfabéticamente
          // this.orderby = ''
          this.orderby = this.ordering
      }

      this.init()
    },
    typeProduct () {
      // console.log(`watch grid catálogo --> typeProduct: ${this.typeProduct}`)
      let type = this.typeProduct.toString()
      // PRODUCTOS DE ORBITA SOLO EN ORBISH (FUNCTION aFilterProductList FILTER-TOP.vue)
      if (type === 'add_extra_products') {
        this.complete = 'false'
        this.add_extra_products = 'True'
      } else if (type === 'special_price') {
        this.complete = 'false'
        this.add_extra_products = 'false'
      } else if (type === 'all' || !this.has_special_prices) {
        this.complete = 'True'
        this.add_extra_products = 'false'
      }
      this.init()
    },
    filterByType () {
      // console.log(`watch grid catálogo --> filterByType: ${this.filterByType}`)
      let type = this.filterByType.toString()
      if (type === 'only_usual_products') {
        this.only_usual_products = 'True' // filtrar por productos más consumidos.
        this.is_outlet = '' // filtar por outlet.
        this.is_eco = '' // filtar por eco.
        this.is_covid = '' // filtar por covid.
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_outlet') {
        this.only_usual_products = 'false'
        this.is_outlet = 'True'
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_eco') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = 'True'
        this.is_covid = ''
      } else if (type === 'is_covid') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = 'True'
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_disinfection') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = 'True'
        this.is_innovative = ''
      } else if (type === 'is_innovative') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = ''
        this.is_innovative = 'True'
      } else {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = ''
        this.is_innovative = ''
      }
      this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-grid{

    .pagination{
      margin: 0;
    }
  }

  .pagination-grid{
    background-color: #ffffff;
    margin: 14px -17px 0;
    padding: 40px 20px 30px;
    border-top: 1px solid #17296E;
    text-align:center;
  }

  .product-list{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .msg-holder{
    text-align: center;
    min-height: 600px;
    margin: 0 -32px;
    padding: 80px 0 130px;
    overflow: hidden;
    background-color: #FAFAFA;

    img{
      margin-bottom: 30px;
    }

    div{
      p{
        font-size: 18px;

        &:last-child{
          margin-top: 30px;
        }
      }
    }
  }
</style>
