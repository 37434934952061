<template lang="pug">
  .banner-sidebar
    div(v-if="typeEcom === 'dicas'")
      //.adplugg-tag(data-adplugg-zone="lateral1_Dicas")
      //.adplugg-tag(data-adplugg-zone="lateral2_Dicas")
      //.adplugg-tag(data-adplugg-zone="lateral3_Dicas")

    div(v-if="typeEcom === 'prisma'")
      .adplugg-tag(data-adplugg-zone="lateral1_Prisma")

      // a(href="#864-109-123" @click="setCategory('864-109-123')")
        img(:src="img.banner2" width="250")

      .adplugg-tag(data-adplugg-zone="lateral2_Prisma")
      .adplugg-tag(data-adplugg-zone="lateral3_Prisma")
      // a(href="#" @click="setCategory('864-779-780')")
          img(:src="img.banner3" width="250")

    div(v-if="typeEcom === 'maripol'")
      // .adplugg-tag(data-adplugg-zone="lateral1_Maripol")
      a(href="#" @click="setCategory('1742-1593')")
        img(:src="img.banner1" width="250")

      .adplugg-tag(data-adplugg-zone="lateral2_Maripol")
      // .adplugg-tag(data-adplugg-zone="lateral3_Maripol")
      a(href="#" @click="setCategory('1742-1674')")
        img(:src="img.banner3" width="250")

    div(v-if="typeEcom === 'pedrosa'")
      .adplugg-tag(data-adplugg-zone="lateral1_Pedrosa")
      .adplugg-tag(data-adplugg-zone="lateral2_Pedrosa")
      .adplugg-tag(data-adplugg-zone="lateral3_Pedrosa")
      //- a(href="#" @click="setCategory('1580-1485')")
        img(:src="img.banner3" width="250")

    div(v-if="typeEcom === 'orbishigiene'")
      a(href="#" @click="setCategory('337-64')")
        img(:src="img.banner1" width="250")
      a(href="#" @click="setCategory('337-11-13')")
        img(:src="img.banner2" width="250")
      a(href="#" @click="setCategory('337-252-253')")
        img(:src="img.banner3" width="250")
</template>

<script>
export default {
  name: 'BannerSidebar',
  props: [],
  data () {
    return {
      img: {
        banner1: require('@/assets/img/banner/banner_col_250x358.jpg'),
        banner2: require('@/assets/img/banner/banner_col_250x208.jpg'),
        banner3: require('@/assets/img/banner/banner_col_250x300.jpg')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  mounted () {
    const ref = this
    setTimeout(() => {
      window.$('.banner-sidebar a[href*="#"]').on({
        click: function (e) {
          e.preventDefault(e)
          const url = window.$(this).attr('href').replace('#', '')
          // console.log('-----' + url)
          ref.setCategory(url)
        }
      })
    }, 2000)
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-sidebar{
  p{
    text-align: center;
    padding-top: 12px;
    margin: 0;
  }

  a, .adplugg-tag{
    display: block;
    width: 250px;
    margin: 0 auto 24px;

  }
}
</style>
