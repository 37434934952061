<template lang="pug">
  #modalCenter(class="modal fade" :class="classOpen" role="dialog")
    .modal-dialog.modal-lg
      .modal-content
        .modal-header
          button(type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          bk-search(
            :type="'modal'"
            :count="centersNum"
            :q="query"
            @toSearch="toSearch")
        .modal-body
          bk-grid(
            :centers="aCenter"
            :message="message"
            :pages="totalPages"
            :count="centersNum"
            :currentPage="currentPage"
            @toPaginate="pagChange")
</template>
<script>
import BkSearch from '@/components/center/Search.vue'
import BkGrid from '@/components/center/Listado-simple.vue'

// importamos servicios Center
import SrvCenter from '@/services/center'

export default {
  name: 'CenterModal',
  components: { BkSearch, BkGrid },
  props: ['classOpen'], // clase para abrir el popup
  data: () => ({
    message: null,
    aCenter: [],
    centersNum: null,
    totalPages: 0,
    init: false, // Para comprobar si es la primera vez que llamamos a la API
    currentPage: 0, // Para saber porque página vamos en la paginación
    query: '', // Donde se guarla la palabra a buscar.
    img: {
      close: require('@/assets/img/icos/cerrar-01.svg')
    }
  }),
  created () {
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.search(pageNum)
    },
    toSearch (query) {
      this.query = query
      this.search(1)
    },
    search (page) {
      // console.log(`---> Search center modal: ${query}`)
      SrvCenter.getCenters(this.query, page)
        .then(res => {
          // console.log(`---> Respuesta servicio Search center modal: `, res)
          if (res === 0) {
            // Si no tenemos un cliente asignado
            this.aCenter = []
            this.centersNum = 0
            this.totalPages = 0
            this.currentPage = 0
          } else if (res.status === 200) {
            // Añadimoslos centros al listado de la modal.
            this.aCenter = res.data.results
            this.centersNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
            // Si solo hay uno lo seleccionamos automáticamente.
            // console.log('--->getCenters: ', res.data.results[0])
            /* if (res.data.count === 1) {
              this.$store.dispatch('setCenter', res.data.results[0])
              // Lo pasamos a su State
              this.$store.commit('SET_CENTER')
            } */
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    openModal () {
      this.query = ''
      this.search(1)
    }
  }
}
</script>
<style lang="scss" scoped>
  #modalCenter{
    .modal-dialog{
      &.modal-lg{
        max-width: 1100px;
        width: 100%;
      }
    }
  }
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    .close{
      margin-top: -22px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    padding-left:0;
    padding-right: 0;
  }
</style>
