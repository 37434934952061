<template lang="pug">
  .facturas-grid
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado facturas].

    .holder-cn(v-if="count")
      .container
        .row.grid-head
          .col-sm-6
            p.name #[translate Nombre del Cliente]
          .col-sm-2
            p.text-right #[translate Nº Factura]
          .col-sm-1
            p.text-center #[translate Fecha]
          .col-sm-1
            p.text-right #[translate Total]
          .col-sm-1
            p.text-center #[translate Ver]
          .col-sm-1
            p.text-center #[translate Descargar]

      .grid-cn(v-if="count")
        .container
          bk-item(v-for="(order, index) in orders" :key="order.id" :order="order" :index="index" @setOnPdfLoading="setOnPdfLoading" @setOffPdfLoading="setOffPdfLoading")
      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/facturas/Item.vue'

export default {
  name: 'OrderListGrid',
  props: ['orders', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      isPdfLoading: false,
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      }
    }
  },
  components: { BkItem, EkPaginate },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    setOnPdfLoading () {
      this.isPdfLoading = true
    },
    setOffPdfLoading () {
      this.isPdfLoading = false
    },
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  }
}
</script>
<style lang="scss" scoped>

.grid-head{
  margin-top: 6px;
  margin-bottom: 6px;
  & > *{
    padding-bottom: 6px;
    border-bottom: 1px solid #1C2C40;
    font-size: 14px;

    &:first-child{
      font-weight: $medium;
    }
  }

  p{
    margin: 0;
  }
}

.grid-cn{
  margin-bottom: 24px;

  & > *{
    padding:0;
  }

  .facturas-list-items{
    &:nth-child(even){
      background-color: rgba(#F1F4F7, .38);
    }

    &:last-child{
        border-bottom: 1px solid #1C2C40;
    }
  }
}

.alert{
  margin-top:40px;
}

</style>