<template lang="pug">
  .item-cn.row
        .product-cn(:class="{'col-sm-4': isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau' , 'col-sm-6': isTypeApp === 'orbishigiene' || isTypeApp === 'ghgorbis' || isTypeApp === 'arablau'}")
          picture.img-cn
            img(:src="checkEmptyImg(product.image_url)")
          h3 {{product.name}}

        .col-sm-3
          ul
            // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
            li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{product.external_id}}
            li(v-else) #[translate Código]: {{product.external_id}}
            li(v-if="product.quantity_by_packet") {{product.quantity_by_packet}} #[translate unidades por envase]
            li(v-if="product.packets_by_package") {{product.packets_by_package}} #[translate envases por paquete]

        //.col-sm-2(v-if="isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau'")
          p.und(:class="{'noStock': !product.stock}") {{product.stock}} #[translate Und].
        .col-sm-2.text-right
          p(v-if="showPrice") {{entries.price | format-money}} {{product.currency_symbol}}
        .col-sm-2.text-right
          .cant(v-if="editQuantity")
            input( type="number" placeholder="0" min="0" :max="limitQuantity" v-model="entries.quantity" @change="checkQuanty")
          p(v-else) {{entries.quantity}}
        .col-sm-1.text-right
          p(v-if="showPrice") {{priceTotal | format-money}} {{product.currency_symbol}}

          // p.facturar-cn(v-if="isTypeApp !== 'pedrosa' && isTypeApp !== 'maripol'") #[translate Facturar]
            span.group
              small(:class="{'active on':isFacturable}") #[translate Si]
              small(:class="{'active':!isFacturable}") #[translate No]
</template>
<script>
import commonMixins from '@/mixins/commons.js'

export default {
  name: 'PedidoSingleItem',
  props: ['entries', 'billable', 'editQuantity'],
  mixins: [commonMixins],
  data () {
    return {
      isFacturable: false,
      maxQuantity: 1,
      imgDefault: require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.entries.product.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.entries.product.obsolete ? this.entries.product.stock : ''
    },
    product () {
      return this.entries.product
    },
    priceTotal () {
      // return this.entries.price * this.entries.quantity
      return this.entries.price * this.entries.quantity
    },
    itemFormato () {
      return this.entries.aditional_measurement_unit
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log(`entries:`, this.entries)
    this.isFacturable = this.billable
    this.maxQuantity = this.entries.quantity
  },
  watch: {
    'entries.quantity' () {
      this.entries.base = this.priceTotal
    }
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkQuanty () {
      // console.log(`obsolete: ${this.product.obsolete}, obsolete_limit: ${this.config_app.obsolete_limit}, quantity: ${this.quantity}`)
      // obsolete_limit: true y product obsolete: true no dejamos que pase el limite del stock.
      if (this.product.obsolete && this.config_app.obsolete_limit) {
        this.entries.quantity = Math.max(Math.min(Math.round(this.entries.quantity), this.limitQuantity), 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .item-cn{
    margin:0;
    padding: 20px 0 30px;
    border-bottom: 1px solid #979797;
    color:#4D4F5C;
  }
  .row{
    display: flex;
    align-items: center;
  }

  .product-cn{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 100px;
    font-size: 14px;
  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    list-style: none;
    padding:0;
    font-size: 12px;
  }

  .und{
    font-size:14px;
    color:#61c844;

    &.noStock{
      color:#ff5466;
    }
  }
  .cant{
      display: inline-block;
      width:56px;
      border: 1px solid #E6E8E9;
      @include roundAll(4);
      overflow: hidden;
      input{
        width: 50px;
    border: none;
    height: 32px;
    line-height: 32px;
    text-align: center;
      }
    }
</style>
