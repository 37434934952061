<template lang="pug">
  #catalog-filterTop
    .combo-common(v-if="aFilterByType && numFilterByType > 1")
      label #[translate Ver Productos]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddAutorizado" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterByTypeItem}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddAutorizado")
          li(v-for="(value, key) in aFilterByType" :class="{'disabled': filterByTypeItem == value}")
            span(:value="key" @click="changeFilterByType(key,value)") {{value}}

    .combo-common(v-if="aFilterProductList && !isPageCatalogoPublic && numFilterProductList > 1 && !user.only_authorized_products")
      label #[translate Filtrar]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddAutorizado" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterProductItem}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddAutorizado")
          li(v-for="(value, key) in aFilterProductList" :class="{'disabled': filterProductItem == value}")
            span(:value="key" @click="changeTypeProduct(key,value)") {{value}}

    .combo-common
      label #[translate Ordenar por]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{order}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddOrder")
          li(v-for="(value, key) in aOrder" :class="{'disabled': order == value}")
            span(:value="key" @click="changeOrderProduct(key,value)") {{value}}
    // p.view-cn
        span
          i(class="fa fa-th-large" aria-hidden="true" :class="{'active': view=='grid'}" @click="changeView('grid')")
          i(class="fa fa-th-list" aria-hidden="true" :class="{'active': view=='list'}" @click="changeView('list')")
</template>
<script>
import {mapGetters} from 'vuex'
import * as types from '@/store/mutation-types'

export default {
  name: 'CatalogFilterTop',
  props: ['view'],
  data () {
    return {
      numFilterProductList: 0, // todos o autorizados.
      filterByTypeItem: '',
      urlEco: false,
      urlCovid: false,
      numFilterByType: 0, // Más consumidos, outlet y eco.
      order: this.$gettext('Alfabéticamente'),
      aOrderElastic: {
        'elastic_relevance': this.$gettext('Relevancia')
      },
      aOrderDefault: {
        // 'name_desc': this.$gettext('Alfabéticamente'),
        'name': this.$gettext('Alfabéticamente'),
        'category': this.$gettext('Orden por familia')
      },
      aOrderConsumo: {
        'consumption_asc': this.$gettext('Consumo: bajo a alto'),
        'consumption_desc': this.$gettext('Consumo: alto a bajo')
      },
      aOrderPrice: {
        'price_asc': this.$gettext('Precio: bajo a alto'),
        'price_desc': this.$gettext('Precio: alto a bajo')
      }
    }
  },
  created () {
    // do something after creating vue instance
    // this.filterProductItem = ''
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    filterProductItem: {
      get () {
        return this.$store.getters.getTypeProduct
      },
      set (val) {
        this.$store.commit('SET_TYPE_PRODUCT', val)
      }
    },
    catalogueOptions () {
      return this.$store.getters.catalogueOptions
    },
    center () {
      return this.$store.state.center.center
    },
    client () {
      return this.$store.state.client.client
    },
    user () {
      return this.$store.getters.user
    },
    aOrder () {
      let aOrder = this.aOrderDefault
      if (this.center && !this.isPageCatalogoPublic) {
        aOrder = {...this.aOrderDefault, ...this.aOrderPrice, ...this.aOrderConsumo}
      }

      if (
        this.config_app.elastic_search &&
        (
          this.$route.name === 'catalogoSearch' ||
          this.$route.name === 'leadSearch' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        aOrder = {...this.aOrderElastic, ...aOrder}
      }
      return aOrder
    },
    aFilterProductList () {
      // filtro ver todos los productos o los autorizados.
      if (!this.client) {
        return null
      }
      let obj = {}
      if (this.client.has_special_prices) {
        obj.special_price = this.$gettext('Autorizados')
      }

      // console.log('obj', obj)
      if (this.client.only_authorized_products && this.$config.type === 'orbishigiene') {
        obj.add_extra_products = this.$gettext('Todos')
      } else if (this.client.has_special_prices) {
        // this.filterProductItem = Object.values(obj)[0]
        obj.all = this.$gettext('Todos')
      }

      this.numFilterProductList = Object.keys(obj).length

      return obj
    },
    aFilterByType () {
      let obj = {}

      obj.all = this.$gettext('Todos')

      if (this.client && this.client.has_usual_products && !this.isPageCatalogoPublic) {
        obj.only_usual_products = this.$gettext('Artículos más consumidos')
      }

      if (this.config_app.is_outlet) {
        obj.is_outlet = this.$gettext('Outlet')
      }

      if (this.config_app.is_eco) {
        obj.is_eco = this.$gettext('Eco')
      }
      if (this.config_app.is_covid) {
        obj.is_covid = this.$gettext('Covid')
      }

      if (this.config_app.is_innovative) {
        obj.is_innovative = this.$gettext('Innovaciones')
      }

      if (this.config_app.is_disinfection) {
        obj.is_disinfection = this.$gettext('Desinfección')
      }

      this.filterByTypeItem = Object.values(obj)[0]
      this.numFilterByType = Object.keys(obj).length
      return obj
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    type_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.type
    },
    isPageCatalogoPublic () {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    }
  },
  mounted () {
    // console.log('mounted')
    this.orderFilterViewProductsState()
  },
  methods: {
    orderFilterViewProductsState () {
      // miramos en la store las opciones de 'ordenar por' y 'ver producto' para aplicarlo o no.
      if (this.$route.query.q) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (this.catalogueOptions.ordering) {
        // si existe un orden lo aplicamos.
        this.changeOrderProductManual(this.catalogueOptions.ordering)
      } else {
        this.changeOrderProductManual('')
      }
      // todos y autorizados
      if (this.catalogueOptions.filterViewProducts) {
        // console.log('Si existe en la store "ver producto" lo aplicamos.')
        this.changeTypeProductManual(this.catalogueOptions.filterViewProducts)
      } else {
        // console.log('No existe en la store "ver producto" lo aplicamos.')
        if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
          this.changeTypeProduct(Object.keys(this.aFilterProductList)[0], Object.values(this.aFilterProductList)[0])
        }
      }

      // mas consumo, outlet y eco.
      // console.log('------filterByType:', this.catalogueOptions.filterByType)
      if (this.catalogueOptions.filterByType) {
        // console.log('Si existe en la store "filterByType" lo aplicamos.')
        this.changeFilterByTypeManual(this.catalogueOptions.filterByType)
      } else {
        // console.log('No existe en la store "filterByType" lo aplicamos.')
        if (this.aFilterByType && Object.keys(this.aFilterByType).length > 0) {
          this.changeFilterByTypeManual(Object.keys(this.aFilterByType)[0], Object.values(this.aFilterByType)[0])
        }
      }
    },
    changeView (v) {
      // Cambia la vista del grid de productos
      if (this.view !== v) {
        this.$emit('toChangeView', [v])
      }
    },
    changeFilterByType (key, value) {
      // console.log(`Filter-top ---> filterByType: k: ${key}, v: ${value}`)
      // Modificamos el ver productos
      this.catalogueOptions.filterByType = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)
      if (this.$route.query.products === 'eco' && key !== 'is_eco') {
        this.$router.replace({'query': null})
      }
      if (this.$route.query.products === 'covid' && key !== 'is_covid') {
        this.$router.replace({'query': null})
      }
      this.filterByTypeItem = value
      this.$emit('toChangeFilterByType', [key])
    },
    changeFilterByTypeManual (key) {
      let value = null
      if (this.aFilterByType && key) {
        value = this.aFilterByType[key]
      } else if (this.aFilterByType && Object.keys(this.aFilterByType).length > 0) {
        key = Object.keys(this.aFilterByType)[0]
        value = Object.values(this.aFilterByType)[0]
        // console.log(`No key - changeTypeProductManual ---> k: ${key}, v: ${value}, client: `, this.client)
      }

      if (key && value) {
        this.changeFilterByType(key, value)
      }
    },
    changeTypeProduct (key, value) {
      // console.log(`Filter-top ---> changeTypeProduct: k: ${key}, v: ${value}`)
      // Modificamos el ver productos
      this.catalogueOptions.filterViewProducts = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)

      this.filterProductItem = value
      this.$emit('toChangeFilterProduct', [value])
    },
    changeTypeProductManual (key) {
      let value = null

      if (this.aFilterProductList && key) {
        value = this.aFilterProductList[key]
      } else if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
        key = Object.keys(this.aFilterProductList)[0]
        value = Object.values(this.aFilterProductList)[0]
        // console.log(`No key - changeTypeProductManual ---> k: ${key}, v: ${value}, client: `, this.client)
      }

      // console.log(`-------changeTypeProductManual ---> k: ${key}, v: ${value}`)
      if (key && value) {
        this.changeTypeProduct(key, value)
      }
    },
    changeOrderProduct (key, value) {
      // console.log(`changeOrderProduct -> key: ${key}, value: ${value} `)
      // Modificamos el orden
      this.catalogueOptions.ordering = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)
      this.order = value
      this.$emit('toChangeOrdering', [key])
    },
    changeOrderProductManual (key) {
      let value = null

      if (key) {
        value = this.aOrder[key]
      }

      if (!key || !value) {
        key = 'name'
        value = this.aOrder[key]
      }

      // console.log(`changeOrderProductManual -> key: ${key}, value: ${value} ---`, this.aOrder)
      this.changeOrderProduct(key, value)
    },
    orderConsumoDesc () {
      this.changeOrderProductManual('consumption_desc')
    },
    orderRelevancia () {
      this.changeOrderProductManual('elastic_relevance')
    }
  },
  watch: {
    '$route' (to, from) {
      // react to route changes...
      // console.log(`watch router -> to ${to.name}, from ${from.name}`)
      if (
        (
          to.name === 'catalogoSearch' ||
          to.name === 'leadSearch' ||
          to.name === 'catalogoPublicSearch'
        ) &&
        this.$route.query.q
      ) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (to.name === 'catalogoList' || to.name === 'leadOferta' || to.name === 'catalogoListPublic') {
        // console.log('watch list, aOrder: ', this.aOrder)
        // console.log('watch list, catalogueOptions: ', this.catalogueOptions)
        /* this.order = Object.values(this.aOrder)[0]
        this.catalogueOptions.ordering = Object.keys(this.aOrder)[0]
        this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions) */
        this.orderFilterViewProductsState()
      }
    },
    center () {
      // console.log(`---ordering: ${this.catalogueOptions.ordering}, center:,` + this.center)
      if (!this.center) {
        this.changeOrderProductManual('name')
      } else if (this.order !== this.$gettext('Consumo: alto a bajo') && this.center) {
        // si está el centro le decimos qu eordene por consumo.
        this.orderConsumoDesc()
      }
    },
    catalogueOptions () {
      // console.log(`---> filterViewProducts: ${this.catalogueOptions.filterViewProducts}`)
      this.changeTypeProductManual(this.catalogueOptions.filterViewProducts)
      this.changeFilterByTypeManual(this.catalogueOptions.filterByType)
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-filterTop{
    text-align: right;
    margin-bottom: 12px;

    & > *{
      display:inline-block;
      margin-bottom:0;
      vertical-align: middle;

      &:not(:last-child){
        margin-right: 24px;
      }
    }
  }

  label{
    color: #494f58;
    font-size: 14px;
    font-weight: $regular;
    line-height: 38px;
  }

  .view-cn{
    display: none!important;
    i{
      color:#c4cdd3;
      @include transition();
      font-size:24px;
      cursor:pointer;

      &.active{
        color:$blue-light;
      }

      &:last-child{
        margin-left:18px;
      }
    }
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    margin-left:12px;
    min-width: 190px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;

      i{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }
</style>
