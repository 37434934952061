<template lang="pug">
  .albaran-list-items
    .container
      .row.box-1
        .col-sm-4(data-toggle="collapse" :data-target="'#demo-'+index")
          span.img-cn
            img(:src="img.derecha" width="8")
          p.name {{order.center.name}}
        .col-sm-2(@click="setOrder")
          p.text-right {{order.number}}
        .col-sm-2(@click="setOrder")
            p(class="text-center" :class="{'isBillable' : order.billable_center}") {{isBillableCenter}}
        .col-sm-1(@click="setOrder")
          p.text-center {{order.delivery_note_datetime | moment("DD-MM-YYYY")}}
        .col-sm-1(@click="setOrder")
          p.text-right(v-if="showPrice") {{order.base | format-money}} {{order.currency_symbol}}
          p.text-right(v-else) &nbsp;
        .col-sm-1(@click="setOrder")
          p.text-center.state-cn
            // span(:class="order.state") {{state}}
            img(:src="img.ver" width="18")
        .col-sm-1(@click="getAlbaranPDF")
          p.text-center.state-cn
            img(:src="img.descargar" width="18")

      .row.box-2(:id="`demo-${index}`" class="collapse")
        .col-sm-6.col-md-3
          p #[translate Identificador]: #[strong {{checkEmpty(order.center.external_code)}}]
          p #[translate Dirección]: #[strong {{checkEmpty(order.center.address)}}]
          p #[translate Localidad]: #[strong {{checkEmpty(order.center.locality)}}]
          p #[translate Provincia]: #[strong {{checkEmpty(order.center.province)}}]
          p #[translate Código Postal]: #[strong {{checkEmpty(order.center.postal_code)}}]
        .col-sm-6.col-md-3
          p #[translate Persona de contacto]: #[strong {{checkEmpty(order.center.contact)}}]
          p #[translate Teléfono]: #[strong {{checkEmpty(order.center.phone)}}]
          p #[translate Horario]: #[strong {{order.center.morning_open_times}} - {{order.center.afternoon_open_times}}]
          p #[translate Observaciones]: #[strong {{checkEmpty(order.center.observations)}}]
</template>
<script>
import SrvOrder from '@/services/albaranes'
import commonMixins from '@/mixins/commons.js'

export default {
  name: 'AlbaranListItem',
  props: ['order', 'index'],
  mixins: [commonMixins],
  data () {
    return {
      img: {
        'derecha': require('@/assets/img/icos/derecha.svg'),
        'ver': require('@/assets/img/icos/ver-oferta.svg'),
        'descargar': require('@/assets/img/icos/ico_pdf.svg')
      }
    }
  },
  created () {
    // console.log(`---> ${this.order.billable_center}`)
  },
  computed: {
    state () {
      let result = '-'
      if (this.order.state_values) {
        this.order.state_values.map(item => {
          if (item.code === this.order.state) {
            result = item.value
          }
        })
      }
      return result
    },
    isBillableCenter () {
      return this.order.billable_center ? this.$gettext('Si') : this.$gettext('No')
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    getAlbaranPDF () {
      this.$emit('setOnPdfLoading')
      SrvOrder.getAlbaranPdf(this.order.id)
        .then(res => {
          if (res.status === 200) {
            const blob = new Blob([res.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Albaran-${this.order.number}.pdf`
            link.click()
            this.$emit('setOffPdfLoading')
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.$emit('setOffPdfLoading')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          this.$emit('setOffPdfLoading')
        })
    },
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    setOrder () {
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'albaranSingle', params: {id: this.order.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
  .albaran-list-items{
    color:#494f58;
  }

  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;

      &.name{
        text-transform: uppercase;
        margin-left: 34px;
        @include ellipsis();
        cursor: pointer;
        @include transition();
      }
    }
  }

  .img-cn{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    height: 22px;
    margin: auto;
    padding-right: 40px;
    img{
      @include transition();
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  [aria-expanded="true"]{
      img{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }

  .box-2{
    padding: 16px 0 46px 34px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;

    strong{
      font-weight: $regular;
    }
  }

  .state-cn{
    /*span {
      width: 156px;
      text-align: center;
      color: $orange;
      margin-left:0;
    }

    img{
      float: right;
    }

    .S{
      color: $green;
    }*/
  }

  .isBillable{
    color: $green-true;
  }

</style>
