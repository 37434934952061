<template lang="pug">
  #ClientListGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .holder-cn
      .container
        .row.grid-head
          .col-sm-4
            p.name #[translate Nombre del Centro]
          .col-sm-2.num-cn
            p.text-right #[translate Nº Pedido Cliente]
          .col-sm-1.num-cn
            p.text-right #[translate Nº Pedido]          
          .col-sm-1
            p.text-center #[translate Facturable]
          .col-sm-1.date-cn
            p #[translate Fecha Pedido]
          .col-sm-1.date-cn
            p #[translate Fecha Envío]
          .col-sm-1.total-cn
            p.text-right(v-if="showTotals") #[translate Total]
            p.text-right(v-else) &nbsp;
          .col-sm-1
            p.text-center #[translate Estado]

      .grid-cn(v-if="count")
        .container
          bk-item(v-for="(order, index) in orders" :key="order.id" :order="order" :index="index"  @toOpenPopupOrderCenter="toOpenPopupOrderCenter")

      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
    .container(v-if="count == 0")
      .row
        .col-sm-12
            p(class="alert alert-info" role="alert") #[translate No se han encontrado pedidos].

    bk-popup(ref="popupOrderCenter")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/order/Item.vue'
import BkPopup from '@/components/order/Popup-center.vue'
import commonMixins from '@/mixins/commons.js'

export default {
  name: 'OrderListGrid',
  components: { EkPaginate, BkItem, BkPopup },
  props: ['orders', 'message', 'pages', 'count', 'currentPage'],
  mixins: [commonMixins],
  data () {
    return {
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      }
    }
  },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    isClient () {
      return this.$store.getters.isClient
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    },
    toOpenPopupOrderCenter (data) {
      // console.log('---toOpenPopupOrderCenter: ', data)
      this.$refs.popupOrderCenter.openModal(data)
    }
  }
}
</script>
<style lang="scss" scoped>

  .grid-head{
    margin-top: 6px;
    margin-bottom: 6px;
    & > *{
      padding-bottom: 6px;
      border-bottom: 1px solid #1C2C40;
      font-size: 14px;

      &:first-child{
        font-weight: $medium;
      }
    }

    p{
      margin: 0;
    }
  }

  .date-cn{
    padding-left: 0;
    padding-right: 0;
  }

  .num-cn{
    padding-left: 0;
    padding-right: 0;
  }

  .grid-cn{
    margin-bottom: 24px;

    & > *{
      padding:0;
    }

    .ClientListItem{
      &:nth-child(even){
        background-color: rgba(#F1F4F7, .38);
      }

      &:last-child{
          border-bottom: 1px solid #1C2C40;
      }
    }
  }

  .alert{
    margin-top:40px;
  }

</style>
