import { render, staticRenderFns } from "./listado-desplegable-item.vue?vue&type=template&id=6f838336&scoped=true&lang=pug"
import script from "./listado-desplegable-item.vue?vue&type=script&lang=js"
export * from "./listado-desplegable-item.vue?vue&type=script&lang=js"
import style0 from "./listado-desplegable-item.vue?vue&type=style&index=0&id=6f838336&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f838336",
  null
  
)

export default component.exports