// Importamos mutaciones
import * as types from '@/store/mutation-types'

const catalogue = {
  state: {
    migasSlug: null, // dónde guardaremos los ID de las categorias el primero es el nivel 0 ,etc... ej: 864-109-391
    supplier: null, // donde guardaremos el ID del proveedor seleccionado. 0 => todos
    pageCurrent: 1, // página por la que vamos en el catálogo.
    typeProduct: '',
    showInfoSoste: false
  },
  actions: {
    setCatalogueMigasSlug ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_MIGAS_SLUG, data)
    },
    setCatalogueSupplier ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_SUPPLIER, data)
    },
    setCataloguePageCurrent ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_PAGE_CURRENT, data)
    }
  },
  mutations: {
    [types.UPDATE_CATALOGUE_MIGAS_SLUG] (state, data) {
      state.migasSlug = data
    },
    [types.UPDATE_CATALOGUE_SUPPLIER] (state, data) {
      state.supplier = data
    },
    [types.UPDATE_CATALOGUE_PAGE_CURRENT] (state, data) {
      state.pageCurrent = data
    },
    [types.SET_TYPE_PRODUCT] (state, data) {
      state.typeProduct = data
    },
    [types.MODAL_INFO_SOSTE_VIEW] (state, data) {
      state.showInfoSoste = data
    }
  },
  getters: {
    catalogueMigasSlug: state => {
      return state.migasSlug
    },
    catalogueSupplier: state => {
      return state.supplier
    },
    cataloguePageCurrent: state => {
      return state.pageCurrent
    },
    getTypeProduct: state => {
      return state.typeProduct
    },
    getShowModalInfoSoste: state => {
      return state.showInfoSoste
    }
  }
}

export default catalogue
