<template lang="pug">
  .banner-search
    

</template>

<script>
export default {
  name: 'BannerSearch',
  props: [],
  data () {
    return {
      img: {
        banner1: require('@/assets/img/banner/banner_sup_50x270.jpg'),
        banner2: require('@/assets/img/banner/banner_sup_50x170.jpg')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-search{

  p{
    text-align: right;
    margin: 0;
  }

  a{
    display: inline-block;
    &:not(:last-child){
      margin-right: 24px;
    }
  }
  .ad_pedrosa{
    display: flex;
    justify-content: space-around;
  }
}
</style>
