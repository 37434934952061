<template lang="pug">
  .lead-create

    .box-search
      .container
        .row
          .col-sm-12
            a(href="#" @click="goBack()") #[translate Volver]
            translate Nuevo Lead

    .container.box-2
      .row(v-if="messageOk")
        .col-sm-12
          div.alert.alert-success.fade.in {{messageOk}}
      .row(v-if="messageError")
        .col-sm-12
          div.alert.alert-danger.fade.in(v-html="messageError")
      .row
        .col-sm-12
          form(@submit.prevent="validate" @keyup.enter="validate")

            .row
              .col-sm-6
                label #[translate Nombre (Razón social)]*
                input.form-control(type="text" v-model="lead.name" :placeholder="placeholder.nombre" :class="{ 'error':errorName }" @focus="resetError")
              .col-sm-6
                label #[translate Comercial]*
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':errorComercial }" type="button" id="ddLeadCommercial" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{comercial.name}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddLeadCommercial")
                    li(v-for="item in comerciales" :class="{'disabled': comercial.name == item.name}")
                      span( @click="changeComercial(item)") {{item.name}}

            .row
              .col-sm-5
                label #[translate Persona de contacto]*
                input.form-control(type="text" v-model="lead.contact" :placeholder="placeholder.contacto" :class="{ 'error':errorContact }" @focus="resetError")
              .col-sm-2
                label #[translate NIF]
                input.form-control(type="text" v-model="lead.national_id" :placeholder="placeholder.nif" :class="{ 'error':errorNif }" @focus="resetError")
              .col-sm-5
                label #[translate Teléfono]*
                input.form-control(type="text" v-model="lead.phone" :placeholder="placeholder.telefono" :class="{ 'error':errorPhone }" @focus="resetError")

            .row
              .col-sm-5
                label #[translate Correo electrónico]*
                input.form-control(type="text" v-model="lead.email" :placeholder="placeholder.email" :class="{ 'error':errorEmail }" @focus="resetError")

            hr

            .row
              .col-sm-5
                label #[translate Dirección]
                input.form-control(type="text" v-model="lead.address_main" :placeholder="placeholder.direccion" :class="{ 'error':errorAddress }" @focus="resetError")
              .col-sm-2
                label #[translate Localidad]
                input.form-control(type="text" v-model="lead.city" :placeholder="placeholder.localidad" :class="{ 'error':errorCity }" @focus="resetError")
              .col-sm-5
                label #[translate Provincia]*
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':errorProvincia }" type="button" id="ddLeadProvincia" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{provincia.name}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddLeadProvincia")
                    li(v-for="item in provincias" :class="{'disabled': provincia.name == item.name}")
                      span( @click="changeProvincia(item)") {{item.name}}

            .row
              .col-sm-6
                label #[translate Código Postal]
                input.form-control(type="text" v-model="lead.postal_code" :placeholder="placeholder.cp" :class="{ 'error':errorCp }" @focus="resetError")

            hr

            .row
              .col-sm-6
                label #[translate Canal]*
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':errorChannel }" type="button" id="ddLeadChannel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{channel.name}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddLeadChannel")
                    li(v-for="item in channels" :class="{'disabled': channel.name == item.name}")
                      span( @click="changeChannel(item)") {{item.name}}

              .col-sm-6
                label #[translate Tarifa]*
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':errorPriceRate }" type="button" id="ddLeadPriceRate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{priceRate.name}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddLeadOrigin")
                    li(v-for="item in priceRates" :class="{'disabled': priceRate.name == item.name}")
                      span( @click="changePriceRate(item)") {{item.name}}

            hr.type-blue

            .row.submit-cn
              .col-sm-6.text-right
                button.btn.btn-cancel( type="button" @click="goBack()") #[translate Cancelar]
              .col-sm-6
                button.btn.btn-action( :class="{'btn-send': enviando}")
                  translate(v-if="!enviando") Guardar
                  translate(v-if="enviando") Guardando
</template>
<script>
// importamos servicios
import SrvLeads from '@/services/lead'
import SrvCommon from '@/services/common'

export default {
  name: 'LeadCreate',
  data () {
    return {
      'lead': {},
      'comercial': {},
      'comerciales': [],
      'provincia': {},
      'provincias': [],
      'channel': {},
      'channels': [],
      'sector': {},
      'sectors': [],
      'busines': {},
      'business': [],
      'priceRate': {},
      'priceRates': [],
      'messageOk': null,
      'messageError': null,
      'errorMsg': false,
      'errorName': false,
      'errorContact': false,
      'errorEmail': false,
      'errorNif': false,
      'errorAddress': false,
      'errorCp': false,
      'errorCity': false,
      'errorPhone': false,
      'errorProvincia': false,
      'errorComercial': false,
      'errorChannel': false,
      'errorSector': false,
      'errorBusines': false,
      'errorPriceRate': false,
      'enviando': false,
      'placeholder': {
        'nombre': this.$gettext('Nombre'),
        'contacto': this.$gettext('Persona de contacto'),
        'email': this.$gettext('Correo electrónico'),
        'nif': this.$gettext('NIF'),
        'direccion': this.$gettext('Dirección'),
        'cp': this.$gettext('Código Postal'),
        'localidad': this.$gettext('Localidad'),
        'telefono': this.$gettext('Teléfono')
      }
    }
  },
  created () {
    this.getProvinces()
    this.getSalesPeople()
    this.getChannels()
    this.getPriceRates()
  },
  computed: {
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    validate () {
      const name = this.lead.name
      const email = this.lead.email
      const phone = this.lead.phone
      const provincia = this.provincia
      const comercial = this.comercial
      const channel = this.channel
      const priceRate = this.priceRate

      this.resetError()

      if (name === undefined || name === '') {
        this.errorMsg = true
        this.errorName = true
      }

      if (!email) {
        this.errorMsg = true
        this.errorEmail = true
      }

      if (!phone) {
        this.errorMsg = true
        this.errorPhone = true
      }

      if (provincia.name === undefined) {
        this.errorMsg = true
        this.errorProvincia = true
      }

      if (comercial.name === undefined) {
        this.errorMsg = true
        this.errorComercial = true
      }

      if (channel.name === undefined) {
        this.errorMsg = true
        this.errorChannel = true
      }

      if (priceRate.name === undefined) {
        this.errorMsg = true
        this.errorPriceRate = true
      }

      if (!this.errorMsg) {
        this.lead.sales_person = comercial.id
        this.lead.province = provincia.id
        this.lead.channel = channel.id
        this.lead.priceRate = priceRate.id
        this.enviando = true

        SrvLeads.createLead(this.lead)
          .then(res => {
            this.enviando = false
            this.messageOk = this.$gettext('Lead creado con exito.')
            this.resetForm()
            window.scrollTo(0, 0)
          }, (error) => {
            let data = error.data
            this.messageError = '<ul>'
            for (const key in data) {
              let value = data[key]
              this.messageError += `<li>${value}</li>`
            }
            this.messageError += '</ul>'

            this.enviando = false
            window.scrollTo(0, 0)
          })
      }
    },
    changeComercial (item) {
      this.comercial = item
      this.resetError()
    },
    changeProvincia (item) {
      this.provincia = item
      this.resetError()
    },
    changeChannel (item) {
      this.channel = item
      this.resetError()
    },
    changePriceRate (item) {
      this.priceRate = item
      this.resetError()
    },
    goBack () {
      this.$router.push({'name': 'leadsList'})
    },
    resetForm () {
      this.lead = {'email': ''}
      this.comercial = {}
      this.provincia = {}
      this.channel = {}
      this.priceRate = {}
    },
    resetError () {
      this.errorMsg = false
      this.messageOk = false
      this.messageError = false
      this.errorEmail = false
      this.errorName = false
      this.errorContact = false
      this.errorEmail = false
      this.errorNif = false
      this.errorAddress = false
      this.errorCp = false
      this.errorCity = false
      this.errorPhone = false
      this.errorComercial = false
      this.errorProvincia = false
      this.errorChannel = false
      this.errorPriceRate = false
    },
    getSalesPeople () {
      SrvCommon.getSalesPeople()
        .then(res => {
          // console.log('getSalesPeople: ', res)
          if (res.status === 200) {
            const data = res.data
            // console.log(`comerciales: `, data.results)
            this.comerciales = data.results
          } else {
            // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
            // window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
          // window.scrollTo(0, 0)
        })
    },
    getProvinces () {
      SrvCommon.getProvinces()
        .then(res => {
          // console.log('getProvinces: ', res)
          if (res.status === 200) {
            const data = res.data
            this.provincias = data.results
          } else {
            // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
            // window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
          // window.scrollTo(0, 0)
        })
    },
    getChannels () {
      SrvCommon.getChannels()
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`Channels: `, data)
            this.channels = data.results
          }
        }, () => {
          // console.log('----->' + error)
        })
    },
    getPriceRates () {
      SrvCommon.getPriceRates()
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            this.priceRates = data.results
          }
        }, () => {
          // console.log('----->' + error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;

    span{
      margin-left: 18px;
      font-weight: $medium;
    }

    strong{
      margin-left: 6px;
      font-weight: $medium;
    }

  }

  .box-2{
    padding:10px 0 40px;

    .alert{
      max-width: $width-form;
      margin-left: auto;
      margin-right: auto;
    }
  }

  form{
    margin:20px auto 26px;
    max-width: $width-form;

    .row + .row{
      margin-top: 22px;
    }

    .btn{
      width:100%;
      max-width: 170px;
      font-weight: $regular;
      text-transform: none;
    }
  }

  label{
    display:block;
    color:#3c3e4b;
    font-size:14px;
    margin-bottom:10px;
    font-weight:$regular;
  }

  input[type="text"]{
    height: 40px;
    font-size: 12px;
    font-weight: $light;
  }

  hr{
    border-top:1px solid $grey-bg;
    margin: 26px -100px;

    &.type-blue{
      border-color: $blue-light;
      margin-top:40px;
    }
  }

  .submit-cn{
    padding-top:10px;
  }

  .alert-warning{
    margin-top: 40px;
  }

  .Select-bk.dropdown{
    width: 100%;
    margin-left: 0;

    .dropdown-toggle{
      max-width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }
</style>
