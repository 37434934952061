<template lang="pug">
  #EstadisticasSidebarLeft
    ul.nivel1
      li(v-for="itemN1 in aTree")
        button(@click="setItem([itemN1.id], [itemN1.node_name], itemN1)" :class="{'active':itemActive==itemN1.id}" data-depth="0")
          img(:class="{rotate: itemN1.children.length}" :src="img.flecha" width="6")
          span.name-cn {{itemN1.node_name}}

        ul.nivel2(v-if="itemN1.children")
          li(v-for="itemN2, index2 in itemN1.children")
            button(@click="setItem([itemN1.id, itemN2.id], [itemN1.node_name, itemN2.node_name], itemN2)" data-depth="2")
              img(:class="{rotate: itemN2.children.length}" :src="img.flecha" width="6")
              span.name-cn(:class="{'active':itemActive==itemN2.id}") {{itemN2.node_name}}

            ul.nivel3(v-if="itemN2.children" :id="'filterSupplier-'+index2" class="collapse" )
              li(v-for="itemN3, index3 in itemN2.children")
                button(@click="setItem([itemN1.id, itemN2.id, itemN3.id], [itemN1.node_name, itemN2.node_name, itemN3.node_name], itemN3)" data-depth="3" :class="{'active':itemActive==itemN3.id}")
                  img(:class="{rotate: itemN3.children.length}" :src="img.flecha" width="6")
                  span.name-cn {{itemN3.node_name}}
                
                ul.nivel4(v-if="itemN3.children" :id="'filterSupplier-'+index3" class="collapse" )
                  li(v-for="itemN4, index4 in itemN3.children")
                    button(@click="setItem([itemN1.id, itemN2.id, itemN3.id, itemN4.id], [itemN1.node_name, itemN2.node_name, itemN3.node_name, itemN4.node_name], itemN4)" :class="{'active':itemActive==itemN4.id}")
                      span.name-cn {{itemN4.node_name}}  
    </template>

<script>
import * as types from '@/store/mutation-types'
export default {
  name: 'EstadisticasSidebarLeft',
  props: ['aTree'],
  data () {
    return {
      itemActive: null,
      depth3Current: null,
      img: {
        flecha: require('@/assets/img/icos/menu-down.svg')
      }
    }
  },
  created () {
  },
  methods: {
    setItem (migasId, migasName, item) {
      // console.log(`id: ${item.id} ,migas id: ${migasId}`)
      this.itemActive = item.id
      this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_OPTIONS, {'migasId': migasId, 'migasName': migasName})

      let mc = event.target
      let depth = mc.getAttribute('data-depth')
      if (depth === '3') {
        if (this.depth3Current !== mc) {
          if (this.depth3Current) {
            this.depth3Current.parentElement.querySelector('.nivel4').classList.toggle('in')
            this.depth3Current.classList.toggle('open')
          }
          this.depth3Current = mc
        } else if (this.depth3Current === mc) {
          this.depth3Current = null
        }
      }

      if (depth === '0') {
        // da a total y cerramos todo.
        let itemsHide = document.querySelectorAll('.nivel3')
        let buttonsHide = document.querySelectorAll('.nivel2 button')
        for (item in itemsHide) {
          if (itemsHide[item].classList) {
            itemsHide[item].classList.remove('in')
          }
        }
        for (item in buttonsHide) {
          if (buttonsHide[item].classList) {
            buttonsHide[item].classList.remove('open')
          }
        }
        this.depth3Current = null
      } else if (depth === '2') {
        mc.parentElement.querySelector('.nivel3').classList.toggle('in')
        mc.classList.toggle('open')
      } else if (depth === '3') {
        mc.parentElement.querySelector('.nivel4').classList.toggle('in')
        mc.classList.toggle('open')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  #EstadisticasSidebarLeft{
    margin: 20px 0 0;
    background-color: #ffffff;
  }

  ul{
    // margin-bottom: 12px;
  }
  li{
    list-style: none;
    button{
      position: relative;
      border:0;
      background-color: transparent;
      display:block;
      width:100%;
      text-align: left;
      font-size: 13px;
      padding-left:0;
      padding-right:0;
      @include transition();

      span{
        display: block;
        padding: 4px 30px 4px 10px;
        pointer-events: none;
      }

      img{
        position: absolute;
        top: 8px;
        left: 8px;
        margin: 0;
        @include transition();
      }

      &.open{
        // background-color: transparent!important;

        .rotate{
            transform: rotate(90deg);
        }
      }

      &:hover{
        background-color: #EBF3FE;
      }
    }
  }

  .nivel1{
    padding-left: 0;

    & > li {
      border-bottom: 1px solid #ededed;

      & > button{
        color: #1C2C40;
        padding-left: 15px;
        text-transform: uppercase;
      }
    }
  }

  .nivel2{
    padding-left:0;
    & > li{
      border-top: 1px solid #ededed;
      & > button{
        color: #1C2C40;
        span {
          padding: 4px 30px 4px 24px;
          &.active{
            background-color: #EBF3FE;
          }
        }
      }
    }
  }

  .nivel3{
    padding-left:0;
    & > li{
      // border-top: 1px solid #ededed;
      // background-color: #fafafa;
      padding-left: 10px;
      & > button{
        color: #1C2C40;
        font-size: 12px;
        padding-left: 20px;
        // background-color: #eef0f5;
        &.active{
          background-color: #eef0f5;
        }
      }
    }
  }

  .nivel4{
     padding-left:0;
     & > li{
       & > button{
         font-size: 12px;
         color: #1C2C40;
         padding-left: 30px;
       }
     }
  }

  .name-cn{
    position: relative;
    &:empty{
      &:before{
        content: '-';
      }
    }
  }
</style>
