import { render, staticRenderFns } from "./Model-content.vue?vue&type=template&id=ba3846a8&scoped=true&lang=pug"
import script from "./Model-content.vue?vue&type=script&lang=js"
export * from "./Model-content.vue?vue&type=script&lang=js"
import style0 from "./Model-content.vue?vue&type=style&index=0&id=ba3846a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba3846a8",
  null
  
)

export default component.exports