<template lang="pug">
  .oferta-single

    .box-search
      .container
        .row
          .col-sm-12
            a(href="#" @click="goBack()") #[translate Volver]
            // translate Oferta:
            // strong {{number}}


    .container(v-if="messageError")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-warning fade in" role="alert") {{messageError}}

    .container(v-if="messageOk")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-success fade in" role="alert") {{messageOk}}

    div(v-if="!messageError")

      .box-1
        .container
          .row
            .col-sm-12
              .date-cn
                span #[translate Fecha de envío de la oferta]:&nbsp;
                strong {{ date | moment("DD-MM-YYYY")}}

      .container.table-1
        .row.table-head
          .holder-cn(v-if="isTypeApp !== 'vario'")
            h3 #[translate Centro]
          .holder-cn
            h3  #[translate Cliente]

        .row.table-grid
          .holder-cn(v-if="center && isTypeApp !== 'vario'")
            p {{center.name}}
            p {{center.address}}
            p ({{center.postal_code}}, {{center.locality}})
          .holder-cn(v-if="center")
            p {{client.name}}
            p {{client.address}}
            p ({{client.postal_code}}, {{client.locality}})

      .container.table-2
        .row.table-head(v-if="isTypeApp === 'vario'")
          .col-sm-3 {{numItems}} #[translate productos]
          .col-sm-3 &nbsp;
          .col-sm-1.text-right.precio-und #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Format]
          .col-sm-1.text-right #[translate Descuento]
          .col-sm-1.text-right #[translate Margin]
          .col-sm-1.text-right #[translate total]
        .row.table-head(v-else)
          .col-sm-3 {{numItems}} #[translate productos]
          .col-sm-4 &nbsp;
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Descuento]
          .col-sm-1.text-right #[translate total]

        .table-grid
          bk-item(v-for="item in items" :key="item.id" :product="item")
      // Ponemos 'kk' para ocultar el bloque a todos los ecom, así lo podemos activar si lo necesitan.
      .box-payment(v-if="isTypeApp === 'kk'")
        .container
          .row
            .col-sm-7
              img(:src="img.payment")
              translate Payment
            .col-sm-3
              strong #[translate Payment options]:
              em  XXXX
            .col-sm-2
              strong #[translate Discount]
              em -X,XX {{moneda}}
      .box-3
        .container
          .row(v-if="isTypeApp === 'vario'")
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li #[translate subtotal]: #[small {{base | format-money}} {{moneda}}]
                li #[translate Discounts]: #[small {{discounts}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]
          .row(v-else)
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]


      .container.box-4
        .row
          .col-sm-2.c-l
            router-link( :to="{ name: 'ofertasList'}" class="btn btn-cancel") #[translate Volver]
          .col-sm-5.text-right.c-c(:class="{'error': errorEmptyPrintFormat}")
            div(v-if="isTypeApp !== 'vario'")
              .dropdown.Select-bk
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="errorEmptyPrintFormat=false") {{printFormatsName}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                  li(v-for="item in printFormats" :class="{'disabled': printFormatsName == item.name}")
                    span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}
              button(class="btn btn-action" :class="{ 'btn-send': enviando}" @click="sendPrintFormat()")
                span(v-if="!enviando") #[translate Enviar]
                span(v-if="enviando") #[translate Enviando]
          .col-sm-5.text-right.c-c(:class="{'error': errorEmptyTypeCart}")
            .dropdown.Select-bk
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddTypeCart" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="errorEmptyTypeCart=false") {{typeCartName}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddTypeCart")
                li(v-for="item in aTypeCart" :class="{'disabled': typeCartName == item.name}")
                  span(:value="item.id" @click="changeOptionTypeCart(item.id, item.name)") {{item.name}}
            button(class="btn btn-orange" :class="{ 'btn-send': enviandoTypeCart}" @click="sendCopyToCart()")
              span(v-show="!enviandoTypeCart") #[translate Convertir]
              span(v-show="enviandoTypeCart")
                img(:src="img.loading" width="20" height="20")
            p
              small.msgError(:class="{'hidden': !errorEmptyTypeCart}") #[translate Selecciones un cliente o un Lead del popup]
            button.hidden(data-toggle="modal" data-target="#modal-oferta" class="btn-modal" ref="showModal")

    bk-modal-oferta(ref="modalOferta" :products="items")
</template>
<script>
import BkItem from '@/components/ofertas/Item-single.vue'
import BkModalOferta from '@/components/ofertas/Modal-content.vue'

// importamos servicios
import SrvOfertas from '@/services/ofertas'

export default {
  name: 'OfertaSingle',
  props: ['id'],
  components: { BkItem, BkModalOferta },
  data () {
    return {
      messageOk: null,
      messageError: null,
      number: null,
      date: null,
      base: null,
      discounts: null,
      tax: null,
      total: null,
      moneda: null,
      details: null,
      center: null,
      client: null,
      lead: null,
      printFormats: [],
      printFormatsName: this.$gettext('Volver a enviar como:'),
      printFormatsId: null,
      items: [],
      enviando: false,
      errorEmptyPrintFormat: false,
      errorEmptyTypeCart: false,
      typeCartName: this.$gettext('Convertir en nuevo/a:'),
      typeCartId: null,
      aTypeCart: [
        {'id': 1, 'name': this.$gettext('Oferta/pedido para cliente')},
        {'id': 2, 'name': this.$gettext('Oferta para lead')}
      ],
      enviandoTypeCart: false,
      img: {
        loading: require(`@/assets/img/btn-loading2.svg`),
        payment: require(`@/assets/img/icos/payment.svg`)
      }
    }
  },
  computed: {
    numItems () {
      if (this.items) {
        return this.items.length
      }
      return 0
    },
    client_lead () {
      return (this.lead) ? this.$gettext('lead') : this.$gettext('cliente')
    },
    clientStore () {
      return this.$store.getters.client
    },
    leadStore () {
      return this.$store.getters.lead
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // eliminamos el lead seleccionado si existe;
    /* this.$store.dispatch('removeLead')
    this.$store.commit('REMOVE_LEAD')

    // eliminamos el Cliente seleccionado si existe;
    this.$store.dispatch('removeClient')
    this.$store.commit('REMOVE_CLIENT')

    // eliminamos el centro seleccionado si existe;
    this.$store.dispatch('removeCenter')
    this.$store.commit('REMOVE_CENTER')

    // vacioamso el Carrito
    this.deleteCart() */

    this.getOferta()
    this.getPrintFormats()
  },
  methods: {
    changeOption (id, name) {
      // console.log(`----- printFormatsId: ${id}, printFormatsName: ${name} `)
      this.printFormatsName = name
      this.printFormatsId = id
    },
    changeOptionTypeCart (id, name) {
      this.typeCartName = name
      this.typeCartId = id

      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
      this.$refs.modalOferta.openModal(this.typeCartId)
    },
    getOferta () {
      SrvOfertas.getOferta(this.id)
        .then(res => {
          // console.log('getOferta: ', res.data)
          if (res.status === 200) {
            const data = res.data
            this.number = data.number
            this.date = data.offer_datetime
            this.base = res.data.base
            this.discounts = res.data.discounts
            this.tax = res.data.tax_total
            this.total = res.data.total
            this.moneda = data.currency_symbol
            this.items = res.data.entries
            this.details = data.details
            this.client = res.data.client
            this.center = res.data.center
            this.lead = res.data.lead
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    getPrintFormats () {
      SrvOfertas.getPrintFormats()
        .then(res => {
          // console.log('getPrintFormats: ', res)
          if (res.status === 200) {
            const data = res.data
            // console.log('getPrintFormats: ', data)
            this.printFormats = data.results
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    sendPrintFormat () {
      // console.log(`id: ${this.id}, printFormatsId: ${this.printFormatsId}`)
      if (!this.printFormatsId) {
        this.errorEmptyPrintFormat = true
      } else if (!this.enviando) {
        this.enviando = true

        SrvOfertas.sendPrintFormat(this.id, this.printFormatsId)
          .then(res => {
            // console.log('sendPrintFormat: ', res)
            if (res.status === 200) {
              this.messageOk = this.$gettext('Oferta enviada.')
              window.scrollTo(0, 0)
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              window.scrollTo(0, 0)
            }
            this.enviando = false
          }, () => {
            // console.log('----->' + error)
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    sendCopyToCart () {
      // console.log(`id: ${this.id}, printFormatsId: ${this.printFormatsId}`)
      // console.log('client:', this.clientStore)
      // console.log('lead', this.leadStore)
      if (!this.typeCartId || (this.typeCartId === 2 && !this.leadStore) || (this.typeCartId !== 2 && !this.clientStore)) {
        this.errorEmptyTypeCart = true
      } else if (!this.enviando) {
        this.enviandoTypeCart = true
        if (this.typeCartId !== 2 && this.clientStore) {
          this.replicarCartClient()
        } else if (this.typeCartId === 2 && this.leadStore) {
          this.replicarCartLead()
        }
      }
    },
    replicarCartClient () {
      // añadimos los productos a la cesta y nos vamos al carro.
      this.items.map((item, index) => {
        // NO PASAMOS CÓDIGO 0
        if (item.product.id !== this.config_app.id_code_0) {
          item.product.discount = item.discount
          this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: item.product, hide: true})
        }
      })
      this.$router.push({'name': 'cartList'})
      return false
    },
    replicarCartLead () {
      // añadimos los productos a la cesta y nos vamos al carro.
      this.items.map((item, index) => {
        // console.log(`quantity: ${item.quantity}, product id: ${item.product.id}, client Id: ${this.clientStore.id}`)
        // NO PASAMOS CÓDIGO 0
        if (item.product.id !== this.config_app.id_code_0) {
          item.product.discount = item.discount
          this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: item.product, hide: true})
        }
      })
      this.$router.push({name: 'leadOfertaCartList'})
      return false
    },
    deleteCart () {
      this.$store.dispatch('deleteCart')
      // this.messageOk = this.$gettext('Carro eliminado.')
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .oferta-single{
    padding:0 0 200px;
  }

  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;

    span{
      margin-left: 18px;
      font-weight: $medium;
    }

    strong{
      margin-left: 6px;
      font-weight: $medium;
    }

  }

  .alert{
    margin-top: 40px;
  }

  .box-1{
    padding: 26px 0 20px;
    font-size: 16px;
    small{
      font-size: 16px;
    }

    .date-cn{
      span{
        font-weight: $bold;
      }
    }

    strong{
      font-weight: $regular;
    }
  }

  .table-1{
    &.full-cn{
      .table-head, .table-grid{
        .holder-cn{
          width: 100%;
        }
      }
    }
    .table-head{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;

      .holder-cn{
        width: 48%;

        &:nth-child(3){
          h3 {
            font-weight: $medium;
          }
        }
      }

      h3{
        border-bottom: 1px solid #1C2C40;
        font-size: 14px;
        font-weight: $regular;
        padding-bottom: 6px;
      }

      p{
        margin: 0;
      }
    }

    .table-grid{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;
      font-size: 14px;
      color:#4D4F5C;

      .holder-cn{
        width: 48%;
        padding: 20px 18px;
        background-color: #F1F4F7;
      }

      strong{
        min-width: 130px;
        display: inline-block;
        font-weight: $regular;
      }
    }
  }

  .table-2{
    .table-head{
      margin: 6px 0;
      & > *{
        padding-bottom: 6px;
        border-bottom: 1px solid #979797;
        font-size: 14px;

        &:first-child{
          font-weight: $medium;
        }
      }

      .precio-und{
        padding-left: 0;
        padding-right: 0;
      }

      p{
        margin: 0;
      }
    }
  }

  .box-3{
    margin:24px 0 30px;

    .row{
      border-bottom: 2px solid #17296E;
      padding-bottom:16px;
      margin: 0;
    }

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 16px;
      margin-bottom: 14px;

      small{
        font-size: 16px;
        float: right;
      }

      &:last-child{
        font-size: 18px;
        font-weight: $medium;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }


  .box-4{
    .row{
      margin: 0;
    }
    .c-l{
      padding-left:0;
    }
    .c-c{
      padding-right:0;

      & > *{
        margin-left: 10px;
      }

      &.error{
        color: $red;

        #ddPrintFormats, #ddTypeCart{
          border-color: $red;
          background-color: rgba($red, .05);
          color: $red;

          i{
            color: $red;
          }
        }
      }
    }

    .msgError{
      color: $red;
    }

    .btn{
      padding:0 20px;
      height: 42px;
      line-height: 42px;
      min-width: 126px;
      font-weight: 14px;
    }
  }

  .Select-bk{
    width: 240px!important;

    .dropdown-toggle{
      height: 42px!important;
      line-height: 42px!important;

      i{
        line-height: 40px!important;
      }
    }
  }

  .box-payment{
    .row{
      margin: -1px 0 0;
      padding: 30px 0;
      border-top: 2px solid #17296E;
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        font-size: 14px;
        color: #1C2C40;

        &:first-child{
          padding-left:0;
          font-size: 16px;

          img{
            margin-right: 20px;
          }
        }

        &:last-child{
          text-align: right;
          padding-right: 0;

          em{
            background-color: #E2E2E2;
            min-width: 70px;
            height: 34px;
            line-height: 34px;
            text-align: right;
            display: inline-block;
            @include roundAll(2);
            padding: 0 8px;
            vertical-align: middle;
          }
        }

        strong{
          font-weight: $regular;
          margin-right: 20px;
        }

        em{
          font-style: normal;
          color: #646464;
        }
      }
    }
  }
</style>
