import { render, staticRenderFns } from "./Helpmessage.vue?vue&type=template&id=3390edbc&scoped=true&lang=pug"
import script from "./Helpmessage.vue?vue&type=script&lang=js"
export * from "./Helpmessage.vue?vue&type=script&lang=js"
import style0 from "./Helpmessage.vue?vue&type=style&index=0&id=3390edbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3390edbc",
  null
  
)

export default component.exports