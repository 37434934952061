import { render, staticRenderFns } from "./Grid-delivery-ranking-center.vue?vue&type=template&id=60af3be6&scoped=true&lang=pug"
import script from "./Grid-delivery-ranking-center.vue?vue&type=script&lang=js"
export * from "./Grid-delivery-ranking-center.vue?vue&type=script&lang=js"
import style0 from "./Grid-delivery-ranking-center.vue?vue&type=style&index=0&id=60af3be6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60af3be6",
  null
  
)

export default component.exports