<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    #modal-limit
      .modal-content
        .modal-header
          button(type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          h2 #[translate Límite de consumo]
        .modal-body
          div
            strong.lineHeight30 #[translate HA SOBREPASADO SU LÍMITE DE CONSUMO]
            p 
              span.lineHeight30 #[translate Su límite de consumo es de ] 
              strong {{ limitPurchase }} €
              br 
              span.lineHeight30 #[translate Va a enviar un pedido a validación. Recuerde que el pedido está sujeto a modificaciones.] 
              br
              span.lineHeight30 #[translate ¿Desea continuar?]
          .buttons
            button(type="button" data-dismiss="modal") #[translate Volver]
            button( @click="sendValidation" data-dismiss="modal") #[translate Continuar]
    span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ModalLimitPurchase',
  props: ['id'],
  data () {
    return {
      img: {
        close: require('@/assets/img/icos/cerrar-01.svg')
      }
    }
  },
  computed: {
    center () {
      return this.$store.getters.center
    },
    limitPurchase () {
      if (this.center !== null && this.center.purchase_limit) {
        return this.center.purchase_limit
      }
    },
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-limit-purchase'
    }
  },
  methods: {
    sendValidation () {
      this.$emit('sendOrder')
    }
  }
}
</script>
<style lang="scss" scoped>
#modal-limit{
  margin: 30px auto;
  max-width: 700px;

}
  /*.modal-header{
    padding-top:30px;
    padding-bottom: 24px;
    background-color: $blue-bg;
    color: $white;

    .close{
      margin-top: -20px;
      font-size: 30px;
      color: $white;
      @include opacity(0.7);
    }
  }

  .modal-body{
    // padding-left:0;
    // padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 16px;
  }*/
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    .close{
      margin-top: -22px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    padding:50px 0 30px 0;
    text-align: center;
    // padding-left:0;
    // padding-right: 0;
    .lineHeight30{
      line-height: 30px;
    }
    .buttons{
          border-top: 1px solid #00adfc;
      max-width: 550px;
      margin: 0 auto;
      padding-top: 19px;
      button{
        background-color: #00adfc;
        padding: 10px;
        border:none;
        color:#fff;
        margin:0 5px;
        width: 180px;
        display: inline-block;
      }
    }
  }

  h2{
    font-size: 18px;
    margin: 0;
  }
</style>
