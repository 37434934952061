import { render, staticRenderFns } from "./Grid-public.vue?vue&type=template&id=dc540b1a&scoped=true&lang=pug"
import script from "./Grid-public.vue?vue&type=script&lang=js"
export * from "./Grid-public.vue?vue&type=script&lang=js"
import style0 from "./Grid-public.vue?vue&type=style&index=0&id=dc540b1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc540b1a",
  null
  
)

export default component.exports