// Importamos mutaciones
import * as types from '@/store/mutation-types'

const dashboardClient = {
  state: {
    options: {
      'filterCatList': [], // dónde guardaremos todas las categorías principales para el listado del filtro.
      'filterCatItem': {'id': '', 'name': ''}, // dónde guardaremos la categoría seleccionada para el filtro.
      'filterCECO': null
    },
    optionsCECO: []
  },
  actions: {
    setFilterCatList ({commit}, data) {
      commit(types.UPDATE_FILTER_CAT_LIST, data)
    },
    setFilterCatItem ({commit}, data) {
      commit(types.UPDATE_FILTER_CAT_ITEM, data)
    }
  },
  mutations: {
    [types.UPDATE_FILTER_CAT_LIST] (state, data) {
      state.options.filterCatList = data
    },
    [types.UPDATE_FILTER_CAT_ITEM] (state, data) {
      state.options.filterCatItem = data
    },
    [types.UPDATE_FILTER_CECO] (state, data) {
      if (data === 'Todos') {
        data = null
      }
      state.options.filterCECO = data
    },
    [types.UPDATE_LIST_CECO] (state, data) {
      state.optionsCECO = data
    }
  },
  getters: {
    filterCatList: state => {
      return state.options.filterCatList
    },
    filterCatItem: state => {
      return state.options.filterCatItem
    },
    getFilterCECO: state => {
      return state.options.filterCECO
    },
    getOptionsCECO: state => {
      return state.optionsCECO
    }
  }
}

export default dashboardClient
