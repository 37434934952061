<template lang="pug">
  #EstadisticasCompras
    .Loader-cn(:class="{'show': apiSend}")
      span.Loader-spinner
    .box-1
      .container
        .row
          .col-sm-12
            h2 #[translate Compras]
        .row
          .col-sm-12
            bk-migas
        .row
          .col-sm-12.text-right
            a.btn.btn-white(v-if="oClientPurchases" @click="urlDownloadXls('client_purchases')")
              img(:src="img.download" width="14")
              translate Descargar xlsx
            filter-ceco
            span.combo-common(v-if="filterCatList.length > 0")
              label #[translate Categoría]:
              .Select-bk.dropdown
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddCat" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterCatItem.name}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddCat")
                  li(v-for="(item, key) in filterCatList" :class="{'disabled': filterCatList == item}")
                    span(:value="item.id" @click="changeFilterCat(item)") {{item.name}}
            date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="month" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

    .container.box-2
      .row
        .col-sm-4.chart-cn.type-num
          .row
            .col-sm-5
              p.title-cn #[translate Período:]
              p.value-cn(v-if="oClientPurchases") {{number(oClientPurchases.period_purchases)}}€
            .col-sm-7
              p.leyenda-cn(v-if="oClientPurchases" ) 
                span(:class="{'positive': oClientPurchases.period_purchases_diff_percentage > 0, 'negative': oClientPurchases.period_purchases_diff_percentage < 0 }") 
                  span(v-if="oClientPurchases.period_purchases_diff_percentage > 0") +
                  span {{oClientPurchases.period_purchases_diff_percentage}}% 
                span vs a.a.
              p.leyenda-cn(v-if="oClientPurchases") 
                span(:class="{'positive': oClientPurchases.period_purchases_diff > 0, 'negative': oClientPurchases.period_purchases_diff < 0 }") 
                  span(v-if="oClientPurchases.period_purchases_diff > 0") +
                  span {{oClientPurchases.period_purchases_diff}} € 
                span vs a.a.
        .col-sm-3.chart-cn.type-euro
          p.title-cn #[translate Año anterior:]
          p.value-cn(v-if="oClientPurchases") {{number(oClientPurchases.previous_period_year_purchases)}}€
        .col-sm-5.chart-legend
          p #[translate Compras período] #[translate Año anterior]

    .container.box-3
      .row
        .col-sm-12
          bar-line(:valuesData="valuesBarLine")
</template>
<script>
import appConfig from '@/config-app.js'
import * as types from '@/store/mutation-types'
import BkMigas from '@/components/client/estadisticas/migas'
import BarLine from '@/components/dashboard/chart/BarLineThree'
import DatePicker from 'vue2-datepicker'
import commonMixins from '@/mixins/commons.js'

// importamos servicios estadisticas
import SrvEstadisticas from '@/services/estadisticas'
import FilterCeco from '@/components/dashboard/FilterCECO'
export default {
  name: 'EstadisticasCompras',
  components: {BkMigas, BarLine, DatePicker, FilterCeco},
  mixins: [commonMixins],
  data () {
    return {
      'apiSend': false, // lo ponemos en true cuando enviemos peticiones a la API para mostrar el preload.
      'oClientPurchases': null, // Dónde guardamos los resultados de Srv getClientPurchases
      'valuesBarLine': {
        'labels': [],
        'dataOne': [],
        'dataTwo': [],
        'dataThree': [],
        'dataColor': {
          'one': {
            'borderColor': '',
            'pointBackgroundColor': ''
          },
          'two': {
            'borderColor': '',
            'pointBackgroundColor': ''
          },
          'three': {
            'borderColor': '',
            'pointBackgroundColor': ''
          }
        }
      },
      'img': {
        download: require('@/assets/img/icos/ficha-01.svg')
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getClientPurchases()
    // console.log(`periodo ini: ${this.$moment().add(-12, 'months').format('YYYY-MM')}, periodo end: ${this.$moment().add(-1, 'months').format('YYYY-MM')}`)
  },
  computed: {
    client () {
      return this.$store.getters.client
    },
    migasId () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasId
    },
    calendar () {
      let before = this.$store.state.client.clientDashboardDate.before
      let after = this.$store.state.client.clientDashboardDate.after
      // console.log(`-----> periodo desde ${after} hasta ${before}`)
      return {
        'before': before,
        'after': after,
        'date': [after, before],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    },
    filterCatList () {
      return this.$store.getters.filterCatList
    },
    filterCatItem () {
      let item = this.$store.getters.filterCatItem
      let list = this.$store.getters.filterCatList
      if (item.name === '' && list.length > 0) {
        return list[0]
      }
      return item
    },
    filterCECO () {
      return this.$store.getters.getFilterCECO
    }
  },
  methods: {
    getClientPurchases () {
      this.apiSend = true // mostramos el preload
      // Generamos el menu dle sidebar izquierdo
      SrvEstadisticas.getClientPurchases(this.migasId, this.calendar.before, this.calendar.after, this.filterCatItem.id)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data Dashboard Resumen: ', res.data)
            this.oClientPurchases = res.data

            this.valuesBarLine = {
              'labels': [],
              'dataOne': [],
              'dataTwo': [],
              'dataThree': [],
              'dataColor': {
                'one': {
                  'borderColor': '#45A5F5',
                  'pointBackgroundColor': '#45A5F5'
                },
                'two': {
                  'borderColor': 'rgba(0,0,0,0)',
                  'pointBackgroundColor': '#71c061'
                },
                'three': {
                  'borderColor': '#2F5EC4',
                  'pointBackgroundColor': '#2F5EC4'
                }
              }
            }

            let chart = this.oClientPurchases.chart
            chart.map(item => {
              // console.log('---', item) // date, num_orders, value_orders
              this.valuesBarLine.labels.push(item.date)
              this.valuesBarLine.dataOne.push(item.purchases_value)
              this.valuesBarLine.dataThree.push(item.previous_year_purchases_value)
            })
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          this.apiSend = false // ocultamos el preload
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        let after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        let before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_DATE, {'after': after, 'before': before})
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getClientPurchases()
      }
    },
    changeFilterCat (item) {
      // console.log(`id: ${item.id}, name: ${item.name}`)
      this.$store.dispatch('setFilterCatItem', item)
    },
    number (num) {
      if (Number.isInteger(num)) {
        // sin decimales
        return this.$options.filters.formatNumber(num)
      } else {
        // con decimales
        return this.$options.filters.formatMoney(num)
      }
    }
  },
  watch: {
    migasId () {
      this.getClientPurchases()
    },
    filterCatItem (newItem, oldItem) {
      // console.log(`watch filterCatItem - id: ${newItem.id}`)
      this.getClientPurchases()
    },
    filterCECO () {
      this.getClientPurchases()
    }
  }
}
</script>
<style lang="scss" scoped>
  #EstadisticasCompras{
    position: relative;
    background-color: $white;
    min-height: 60vh;
    padding: 24px 28px;
  }

  .container{
    padding: 0;
  }

  .box-1{
    position: relative;
    padding-bottom: 10px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row{
      display: flex;
      align-items: center;
    }

    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }

    label{
      font-weight: regular;
    }
    .btn-white{
      font-size: 13px;
      height: 34px;
      line-height: 34px;
      border-color: #E2E2E2;
      padding: 0 20px;
      margin-right: 14px;
      color: rgba(100, 100, 100, 0.7);
      img{
        margin-right: 6px;
      }
    }
  }

  .box-2{
    margin-top: 20px;
     & > .row{
       display: flex;
     }
     
  }

  .chart-cn{
    .title-cn{
      color:#2c373f;
      font-size: 16px;
      margin-bottom: 2px;
    }

    .value-cn{
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    .leyenda-cn{
      font-size: 14px;
      color: #6a889e;
      .positive{
        color: $green;
      }
      .negative{
        color: $red;
      }
      em{
        font-size: 16px;
        color: #5d6368;
        font-style: normal;
      }

      strong{
        position: relative;
        display: inline-block;
        color: #26d119;
        font-weight: $regular;
        &:before{
          content:'▴';
          display: inline-block;
          margin-right: 4px;
        }

        &.red{
          color: #f80000;
          &:before{
            content:'▾';
          }
        }
      }

    }

    &.type-num{
      border-right: 2px solid #e6eaee;
      .value-cn{
        color: #45A5F5;
      }
    }

    &.type-euro{
      .value-cn{
        color: #2F5EC4;
      }
    }
  }

  .box-3{
    margin-top: 60px;
    padding-bottom: 60px;
  }

  .chart-legend{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    span{
      position: relative;
      display: inline-block;
      margin-right:16px;

      &:before{
        content:'';
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 6px;
        vertical-align: middle;
      }

      &:nth-child(1){
        &:before{
          background-color: #45A5F5;
        }
      }
      &:nth-child(2){
        &:before{
          background-color: #2F5EC4;
        }
      }
    }
  }

</style>
